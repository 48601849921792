export default [
  {
    path: '/',
    name: 'landing',
    redirect: '/login',
    component: () => import('@/views/Layout.vue'),
    meta: {
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Home.vue'),
    meta: {
      requireAuth: true,
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/testPage',
    name: 'test-page-only',
    component: () => import('@/views/dev/Tour.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
  {
    path: '/second-page',
    name: 'second-page',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      pageTitle: 'Second Page',
      breadcrumb: [
        {
          text: 'Second Page',
          active: true,
        },
      ],
    },
  },

  // {
  //   path: '/payout',
  //   name: 'merchant-payout',
  //   redirect: 'payout/',
  //   component: () => import('@/views/merchants/Page.vue'),
  //   children: [
  //     {
  //       path: '/',
  //       name: 'merchant-payout-view',
  //       component: () => import('@/views/merchants/payout/payoutViewPage.vue'),
  //       meta: {
  //         requireAuth: true,
  //         pageTitle: 'Payout',
  //         breadcrumb: [
  //           {
  //             text: 'Payout',
  //             active: true,
  //           },
  //         ],
  //       },
  //     },
  //     {
  //       path: '/payout/self-settlement',
  //       name: 'merchant-self-settlement-view',
  //       component: () => import('@/views/merchants/self-settlement/SelfSettlementViewPage.vue'),
  //       meta: {
  //         requireAuth: true,
  //         pageTitle: 'Payout',
  //         breadcrumb: [
  //           {
  //             text: 'Payout',
  //             active: true,
  //           },
  //         ],
  //       },
  //     },
  //   ],
  // },

  {
    path: '/settlement',
    name: 'merchant-settlement',
    redirect: 'settlement/view',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'view',
        name: 'merchant-settlement-view',
        component: () => import('@/views/merchants/settlement/settlementViewPage.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Settlement',
          breadcrumb: [
            {
              text: 'Settlement',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/shipping',
    name: 'merchant-shipping-zone',
    redirect: 'shipping/zones',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'rates',
        name: 'merchant-shipping-rate-view',
        component: () => import('@/views/merchants/shipping/shippingRateViewPage.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Shipping Rate',
          breadcrumb: [
            {
              text: 'Shipping Rate',
              active: true,
            },
          ],
        },
      },
      {
        path: 'zones',
        name: 'merchant-shipping-zone-view',
        component: () => import('@/views/merchants/shipping/shippingZoneViewPage.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Shipping Zone',
          breadcrumb: [
            {
              text: 'Shipping Zone',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/refund',
    name: 'merchant-refund',
    redirect: 'refund/view',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'view',
        name: 'merchant-refund-view',
        component: () => import('@/views/merchants/refund/refundViewPage.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Refund',
          breadcrumb: [
            {
              text: 'Refund',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/pools',
    name: 'merchant-pools',
    redirect: 'pools/list',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'eol/list2',
        name: 'merchant-virtual-account-datatable',
        component: () => import('@/views/merchants/virtualAccounts/singleVirtualPage.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Virtual Accounts',
          breadcrumb: [
            {
              text: 'Virtual Accounts',
              active: true,
            },
          ],
        },
      },

      {
        path: 'list',
        name: 'merchant-virtual-account-master-datatable',
        component: () => import('@/views/merchants/virtualAccounts/masterVirtualPage.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Virtual Accounts',
          breadcrumb: [
            {
              text: 'Virtual Accounts',
              active: true,
            },
          ],
        },
      },
      // {
      //   path: 'list',
      //   name: 'merchant-pools-datatable',
      //   component: () => import('@/views/merchants/virtualAccounts/PoolPage.vue'),
      //   meta: {
      //     pageTitle: 'Pools',
      //     breadcrumb: [
      //       {
      //         text: 'Pools',
      //         active: true,
      //       },
      //     ],
      //   },
      // },
    ],
  },
  {
    path: '/collections',
    name: 'merchant-collections',
    redirect: 'collections/list',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'list',
        name: 'merchant-collections-datatable',
        component: () => import('@/views/merchants/newCollections/datatable.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Collections',
          breadcrumb: [
            {
              text: 'Collections',
              active: true,
            },
            {
              text: 'List',
              active: true,
            },
          ],
        },
      },
      {
        path: 'payments',
        name: 'merchant-collections-payments',
        component: () => import('@/views/merchants/newCollections/paymentList.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Payments',
          breadcrumb: [
            {
              text: 'Payments',
              active: true,
            },
            {
              text: 'List',
              active: true,
            },
          ],
        },
      },
      {
        path: 'create-bill-form',
        name: 'merchant-collections-new-bill-form',
        component: () => import('@/views/merchants/newCollections/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Collections',
              active: true,
            },
            {
              text: 'New Bill Form',
              active: true,
            },
          ],
        },
      },
      {
        path: 'create-payment-form',
        name: 'merchant-collections-new-payment-form',
        component: () => import('@/views/merchants/newCollections/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Collections',
              active: true,
            },
            {
              text: 'New Payment Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'merchant-collections-form',
        component: () => import('@/views/merchants/newCollections/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Collections',
              active: true,
            },
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id/bills/create',
        name: 'merchant-bills-form',
        component: () => import('@/views/merchants/bills/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Collections',
              active: true,
            },
            {
              text: 'Bill',
              active: true,
            },
            {
              text: 'Create New Bill Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id/bills/create-direct-payment',
        name: 'merchant-bills-paymentform',
        component: () => import('@/views/merchants/bills/directPaymentForm.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Collections',
              active: true,
            },
            {
              text: 'Bill',
              active: true,
            },
            {
              text: 'Create New Bill Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id/bills/view/:billId',
        name: 'merchant-bills-view',
        component: () => import('@/views/merchants/bills/view.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'View Bill',
          breadcrumb: [
            {
              text: 'Collections',
              active: true,
            },
            {
              text: 'Bill',
              active: true,
            },
            {
              text: 'View Bill',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/customers',
    name: 'merchant-customers',
    redirect: 'customers/datatable',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'datatable',
        name: 'merchant-customers-datatable',
        component: () => import('@/views/merchants/customers/datatable.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: false,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'merchant-customers-new',
        component: () => import('@/views/merchants/customers/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'merchant-customers-form',
        component: () => import('@/views/merchants/customers/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/pages',
    name: 'merchant-pages',
    redirect: 'pages/datatable',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'datatable',
        name: 'merchant-pages-datatable',
        component: () => import('@/views/merchants/pages/datatable.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: false,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'merchant-pages-new',
        component: () => import('@/views/merchants/pages/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'merchant-pages-form',
        component: () => import('@/views/merchants/pages/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/products',
    name: 'merchant-products',
    redirect: 'products/datatable',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'datatable',
        name: 'merchant-products-datatable',
        component: () => import('@/views/merchants/products/datatable.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: false,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'merchant-products-new',
        component: () => import('@/views/merchants/products/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'merchant-products-form',
        component: () => import('@/views/merchants/products/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/products-subcategories',
    name: 'merchant-products-subcategories',
    redirect: 'products-subcategories/list',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'list',
        name: 'merchant-products-subcategories-datatable',
        component: () => import('@/views/merchants/products-subcategories/datatable.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Product Sub Categories',
          breadcrumb: [
            {
              text: 'Product Sub Categories',
              active: true,
            },
            {
              text: 'List',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'merchant-products-subcategories-new',
        component: () => import('@/views/merchants/products-subcategories/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Product Sub Categories',
              active: true,
            },
            {
              text: 'Create New Sub Category',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'merchant-products-subcategories-form',
        component: () => import('@/views/merchants/products-subcategories/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Product Sub Categories',
              active: true,
            },
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/products-categories',
    name: 'merchant-products-categories',
    redirect: 'products-categories/list',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'list',
        name: 'merchant-products-categories-datatable',
        component: () => import('@/views/merchants/products-categories/datatable.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Product Categories',
          breadcrumb: [
            {
              text: 'Product Categories',
              active: true,
            },
            {
              text: 'List',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'merchant-products-categories-new',
        component: () => import('@/views/merchants/products-categories/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Product Categories',
              active: true,
            },
            {
              text: 'Create New Category',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'merchant-products-categories-form',
        component: () => import('@/views/merchants/products-categories/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Product Categories',
              active: true,
            },
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/customer-bills',
    name: 'merchant-customer-bills',
    redirect: 'customer-bills/datatable',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'datatable',
        name: 'merchant-customer-bills-datatable',
        component: () => import('@/views/merchants/customerBills/datatable.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: false,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'merchant-customer-bills-new',
        component: () => import('@/views/merchants/customerBills/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'merchant-customer-bills-form',
        component: () => import('@/views/merchants/customerBills/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/business-owner-details',
    name: 'merchant-business-owner-details',
    redirect: 'business-owner-details/datatable',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'datatable',
        name: 'merchant-business-owner-details-datatable',
        component: () => import('@/views/merchants/businessOwnerDetails/datatable.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: false,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'merchant-business-owner-details-new',
        component: () => import('@/views/merchants/businessOwnerDetails/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'merchant-business-owner-details-form',
        component: () => import('@/views/merchants/businessOwnerDetails/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/reports',
    name: 'merchant-reports',
    redirect: 'reports/view',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'view',
        name: 'merchant-reports-viewr',
        component: () => import('@/views/merchants/reports/view.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Reports',
        },
      },
      {
        path: 'view/:type/:selectedDate',
        name: 'merchant-reports-viewer',
        component: () => import('@/views/merchants/reports/reportViewer.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Reports',
        },
      },
    ],
  },
  // {
  //   path: '/subscriptions',
  //   name: 'merchant-subscriptions',
  //   redirect: 'subscriptions/view',
  //   component: () => import('@/views/merchants/Page.vue'),
  //   children: [
  //     {
  //       path: 'view',
  //       name: 'merchant-subscriptions-datatable',
  //       component: () => import('@/views/merchants/subscriptions/index.vue'),
  //       meta: {
  //         requireAuth: true,
  //         pageTitle: 'Report',
  //         breadcrumb: [
  //           {
  //             text: 'Account',
  //             active: true,
  //           },
  //           {
  //             text: 'Report',
  //             active: true,
  //           },
  //         ],
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/account',
  //   name: 'merchant-info-view',
  //   component: () => import('@/views/merchants/info/index.vue'),
  //   meta: {
  //     requireAuth: true,
  //     pageTitle: 'Info',
  //     breadcrumb: [
  //       {
  //         text: 'Account',
  //         active: true,
  //       },
  //       {
  //         text: 'Info',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/info',
    name: 'merchant-info',
    redirect: 'info/view',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'view',
        name: 'merchant-info-view',
        component: () => import('@/views/merchants/info/index.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Info',
          breadcrumb: [
            {
              text: 'Account',
              active: true,
            },
            {
              text: 'Info',
              active: true,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'merchant-info-new',
        component: () => import('@/views/merchants/info/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'merchant-info-form',
        component: () => import('@/views/merchants/info/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/api',
    name: 'merchant-api',
    redirect: 'api/datatable',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'datatable',
        name: 'merchant-api-datatable',
        component: () => import('@/views/merchants/merchantAPI/datatable.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: false,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'merchant-api-new',
        component: () => import('@/views/merchants/merchantAPI/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'merchant-api-form',
        component: () => import('@/views/merchants/merchantAPI/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
      {
        path: ':mode/:id/reports/:type/:selectedDate',
        name: 'merchant-api-reports-viewer',
        component: () => import('@/views/merchants/merchantAPI/apiReportViewer.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
    ],
  },

  {
    path: '/whitelist',
    name: 'merchant-whitelist',
    redirect: 'whitelist/datatable',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'datatable',
        name: 'merchant-whitelist-datatable',
        component: () => import('@/views/merchants/merchantWhitelist/datatable.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: false,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'merchant-whitelist-new',
        component: () => import('@/views/merchants/merchantWhitelist/Form.vue'),
        meta: {
          requireAuth: true,
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
    ],
  },

  /* {
    path: '/merchants',
    name: 'administration',
    redirect: '/merchants/users',
    component: () => import('@/views/Layout.vue'),
    children: [
      {
        path: '/merchants/users',
        name: 'administration-users',
        redirect: '/merchants/users/datatable',
        component: () => import('@/views/merchants/Page.vue'),
      },
    ],
  },
  {
    path: '/merchant/users',
    name: 'merchant-users',
    redirect: 'users/datatable',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'datatable',
        name: 'merchant-users-datatable',
        component: () => import('@/views/merchants/users/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: false,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'merchant-users-new',
        component: () => import('@/views/merchants/users/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'merchant-users-form',
        component: () => import('@/views/merchants/users/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/catalogs',
    name: 'merchant-catalogs',
    redirect: 'catalogs/datatable',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'datatable',
        name: 'merchant-catalogs-datatable',
        component: () => import('@/views/merchants/catalogs/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: false,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'merchant-catalogs-new',
        component: () => import('@/views/merchants/catalogs/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'merchant-catalogs-form',
        component: () => import('@/views/merchants/catalogs/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/accounts',
    name: 'merchant-accounts',
    redirect: 'accounts/datatable',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'datatable',
        name: 'merchant-accounts-datatable',
        component: () => import('@/views/merchants/accounts/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: false,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'merchant-accounts-new',
        component: () => import('@/views/merchants/accounts/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'merchant-accounts-form',
        component: () => import('@/views/merchants/accounts/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/discounts',
    name: 'merchant-discounts',
    redirect: 'discounts/datatable',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'datatable',
        name: 'merchant-discounts-datatable',
        component: () => import('@/views/merchants/discounts/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: false,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'merchant-discounts-new',
        component: () => import('@/views/merchants/discounts/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'merchant-discounts-form',
        component: () => import('@/views/merchants/discounts/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/domains',
    name: 'merchant-domains',
    redirect: 'domains/datatable',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'datatable',
        name: 'merchant-domains-datatable',
        component: () => import('@/views/merchants/domains/datatable.vue'),
        meta: {
          pageTitle: 'Datatable',
          breadcrumb: [
            {
              text: 'Datatable',
              active: false,
            },
          ],
        },
      },
      {
        path: 'new',
        name: 'merchant-domains-new',
        component: () => import('@/views/merchants/domains/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
      {
        path: ':mode/:id',
        name: 'merchant-domains-form',
        component: () => import('@/views/merchants/domains/Form.vue'),
        meta: {
          pageTitle: 'Form',
          breadcrumb: [
            {
              text: 'Form',
              active: false,
            },
          ],
        },
      },
    ],
  },
   */
  {
    path: '/stores',
    name: 'storefront-landing',
    redirect: 'stores/manage',
    component: () => import('@/views/merchants/Page.vue'),
    children: [
      {
        path: 'manage',
        name: 'merchant-store-manage',
        component: () => import('@/views/merchants/manageStore/manageStorePage.vue'),
        meta: {
          pageTitle: 'Info',
          breadcrumb: [
            {
              text: 'Info',
              active: false,
            },
          ],
        },
      },
      {
        path: 'store-products',
        name: 'merchant-store-product-manage',
        component: () => import('@/views/merchants/manageStore/ManageStoreProductPage.vue'),
        meta: {
          pageTitle: 'Info',
          breadcrumb: [
            {
              text: 'Info',
              active: false,
            },
          ],
        },
      },
      {
        path: 'create',
        name: 'merchant-create-store',
        component: () => import('@/views/merchants/manageStore/createStorePage.vue'),
        meta: {
          pageTitle: 'Create Store',
          breadcrumb: [
            {
              text: 'create Store',
              active: false,
            },
          ],
        },
      },
      {
        path: 'order-management',
        name: 'merchant-store-order-management',
        component: () => import('@/views/merchants/manageStore/OrderManagementPage.vue'),
        meta: {
          pageTitle: 'Order Management',
          breadcrumb: [
            {
              text: 'Order Management',
              active: false,
            },
          ],
        },
      },
    ],
  },
]
